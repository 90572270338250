import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable()
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;
  constructor() {
    const appInsightsConnectionString =
      window['__env'].appInsightsConnectionString;
    if (appInsightsConnectionString) {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: appInsightsConnectionString,
          enableAutoRouteTracking: true,
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    if (this.appInsights) {
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    if (this.appInsights) {
      this.appInsights.trackMetric(
        { name: name, average: average },
        properties
      );
    }
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({
        exception: exception,
        severityLevel: severityLevel,
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }
}
