import { Pipe, PipeTransform } from '@angular/core';
import { fullAddress, ThreeAddresses } from '@shared/utils/address-utilities';

@Pipe({
  name: 'fullAddress',
})
export class FullAddressPipe implements PipeTransform {
  transform(addresses: ThreeAddresses, removeRd: boolean = false): string {
    return fullAddress(addresses, removeRd);
  }
}
