import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SlaughterPeriod } from './types/booking';

@Injectable({
  providedIn: 'root',
})
export class SlaughterPeriodService {
  private readonly _apiUrl = `${window['__env'].apiUrl}/slaughterperiods`;

  constructor(private httpClient: HttpClient) {}

  public getSlaughterPeriods(
    beginDate: string = '',
    endDate: string = '',
    areaId: number = 0,
    onlyUncompleted: boolean = false,
    onlyCompleted: boolean = false,
    takeLast: number = null,
    takeFirstUncompleted: number = null,
    takeLastCompleted: number = null
  ): Observable<any> {
    return this.httpClient.get(
      `${this._apiUrl}?beginDate=${beginDate}&endDate=${endDate}&areaId=${areaId}` +
        `&onlyUncompleted=${onlyUncompleted}&onlyCompleted=${onlyCompleted}` +
        (takeLast !== null ? `&takeLast=${takeLast}` : '') +
        (takeFirstUncompleted !== null
          ? `&takeFirstUncompleted=${takeFirstUncompleted}`
          : '') +
        (takeLastCompleted !== null
          ? `&takeLastCompleted=${takeLastCompleted}`
          : '')
    );
  }

  public getSlaughterPeriodsRequest(
    req: SlaughterPeriodRequest = {}
  ): Observable<SlaughterPeriod[]> {
    // Provide defaults
    req = {
      beginDate: '',
      endDate: '',
      areaId: 0,
      onlyUncompleted: false,
      onlyCompleted: false,
      ...req,
    };
    const params = new HttpParams({ fromObject: req as any });
    return this.httpClient.get<SlaughterPeriod[]>(this._apiUrl, { params });
  }

  public getSlaughterPeriod(id: number): Observable<SlaughterPeriod> {
    return this.httpClient.get<SlaughterPeriod>(`${this._apiUrl}/${id}`);
  }

  public getFuturePeriods(): Observable<SlaughterPeriod[]> {
    return this.httpClient
      .get<SlaughterPeriod[]>(`${this._apiUrl}/future`)
      .pipe(
        map((periods) =>
          periods.map((period) => ({
            ...period,
            beginDate: new Date(period.beginDate as any),
          }))
        )
      );
  }

  public update(updates: UpdateSlaughterPeriod[]): Observable<void> {
    return this.httpClient.put<void>(this._apiUrl, updates);
  }
}

export interface SlaughterPeriodRequest {
  beginDate?: string;
  endDate?: string;
  areaId?: number;
  onlyUncompleted?: boolean;
  onlyCompleted?: boolean;
  takeLast?: number;
  takeFirstUncompleted?: number;
  takeLastCompleted?: number;
}

export interface UpdateSlaughterPeriod {
  id: number;
  financialMonth: number;
}
