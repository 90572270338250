import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../shared/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      tap(
        (isAuthenticated) =>
          isAuthenticated || this.authService.login(state.url)
      ),
      switchMap((isAuthenticated) =>
        isAuthenticated
          ? this.userService.registerUpdateCurrentUser().pipe(
              map(() => isAuthenticated),
              catchError(() => of(isAuthenticated))
            )
          : of(isAuthenticated)
      )
    );
  }
}
