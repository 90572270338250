import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './@auth/auth.service';
import { ApplicationInsightsService } from '@shared/services/application-insights.service';
import { RaygunWrapper } from './app.raygun.setup';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private applicationInsightService: ApplicationInsightsService,
    private router: Router
  ) {
    this.configure();
    this.applicationInsightService.logPageView('Home', '/');
  }

  public ngOnInit() {
    this.router.events.subscribe((event) => {
      // Track page views when the NavigationEnd event occurs
      if (event instanceof NavigationEnd) {
        RaygunWrapper.handleEvent({
          type: 'pageView',
          path: event.url,
        });
      }

      // Track navigation errors when the NavigationError event occurs
      if (event instanceof NavigationError) {
        // Track navigation error
        RaygunWrapper.handleError(event.error);
      }
    });
  }

  private configure() {
    this.authService.runInitialLoginSequence();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
