export enum Roles {
  LivestockCoordinator = 'Livestock_Coordinator',
  LivestockManager = 'Livestock_Manager',
  SystemAdministrator = 'System_Administrator',
  LivestockAdministrator = 'Livestock_Administrator',
  LivestockRegionalManager = 'Livestock_Regional_Manager',
  LivestockRepresentative = 'Livestock_Representative',
  TransportCoordinator = 'Transport_Coordinator',
  LivestockYards = 'Livestock_Yards',
  SOPAdministrator = 'SOP_Administrator',
}
