export class Property {
  id: number;
  name: string;
}

export class SmartTableConfig {
  filterConfig: SmartTableFilterConfig[];
  sortConfig: SmartTableSortConfig[];
  pagingConfig: SmartTablePagingConfig;

  constructor(
    filterConfig?: SmartTableFilterConfig[],
    sortConfig?: SmartTableSortConfig[],
    pagingConfig?: SmartTablePagingConfig
  ) {
    this.filterConfig = filterConfig;
    this.sortConfig = sortConfig;
    this.pagingConfig = pagingConfig;
  }

  public isEmpty() {
    return !(
      this.filterConfig?.length ||
      this.sortConfig?.length ||
      this.pagingConfig?.page ||
      this.pagingConfig?.perPage
    );
  }
}

export class SmartTableFilterConfig {
  field: string;
  search: number | string | boolean;

  constructor(field: string, search: number | string | boolean) {
    this.field = field;
    this.search = search;
  }
}

export class SmartTableSortConfig {
  field: string;
  direction: string;

  constructor(field: string, direction: string) {
    this.field = field;
    this.direction = direction;
  }
}

export class SmartTablePagingConfig {
  page: number;
  perPage: number;

  constructor(page: number, perPage: number) {
    this.page = page;
    this.perPage = perPage;
  }
}

export const STOCK_CLASSES: Property[] = [
  { name: 'Bovine', id: 1 },
  { name: 'Ovine', id: 2 },
];

export const RETURN_TO_WIZARD: string = 'returnToWizard';

export interface SelectOption<T> {
  value: T;
  text: string;
  isDefault?: boolean;
}

export interface SelectOptionWithChildren<T> extends SelectOption<T> {
  children?: SelectOptionWithChildren<any>[];
}

export const getDefaultOption = (
  options: SelectOption<any>[]
): SelectOption<any> => {
  return options.find((option) => option.isDefault);
};
