import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../@auth/auth.service';
import { BuyerService } from '../../shared/services/buyer.service';

@Component({
  selector: 'ngx-switch',
  templateUrl: './switch.component.html',
})
export class SwitchComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private restart = !!this.activatedRoute.snapshot.queryParams['restart'];
  private stockBuyerNumber = Number(
    this.activatedRoute.snapshot.queryParams['stockBuyerNumber']
  );

  constructor(
    private authService: AuthService,
    private buyerService: BuyerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!this.restart) {
      var url = new URL(window.location.href);
      url.searchParams.set('restart', 'true');
      window.location.href = url.href;
    } else {
      this.buyerService
        .getStockBuyerProfiles()
        .pipe(
          takeUntil(this.destroy$),
          tap((profiles) => {
            const stockBuyerProfile = profiles.find(
              (sbp) => !sbp.isDefault && sbp.number === this.stockBuyerNumber
            );
            this.authService.setStockBuyerProfile(stockBuyerProfile);
          })
        )
        .subscribe(() => this.router.navigateByUrl('/'));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
