import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbCardModule,
  NbTreeGridModule,
  NbButtonModule,
  NbIconModule,
  NbInputModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { SharedModule } from '@shared/shared.module';
import { ChangeContractConfirmationComponent } from './change-contract-confirmation/change-contract-confirmation.component';
import { ContractConfirmationRoutingModule } from './contract-confirmation-routing.module';
import { ContractConfirmationComponent } from './contract-confirmation.component';
import { NbSecurityModule } from '@nebular/security';

@NgModule({
  declarations: [
    ContractConfirmationComponent,
    ChangeContractConfirmationComponent,
  ],
  imports: [
    CommonModule,
    ContractConfirmationRoutingModule,
    NbCardModule,
    Angular2SmartTableModule,
    NbTreeGridModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    FormsModule,
    SharedModule,
    NbSpinnerModule,
    NbSecurityModule,
  ],
})
export class ContractConfirmationModule {}
