import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ViewCell } from 'angular2-smart-table';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ngx-input-view',
  template: `
    <input
      class="input-view form-control"
      nbInput
      type="{{ inputType }}"
      [attr.inputmode]="inputMode"
      min="0"
      [(ngModel)]="rowData[rowColumnName]"
      [ngStyle]="{ width: fullWidth ? '100%' : '4rem' }"
      [disabled]="disabled.asObservable() | async"
    />
  `,
  styleUrls: ['./input-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputViewComponent implements ViewCell {
  @Input() value: string | number;
  @Input() rowData: any = {};
  @Input() rowColumnName: string;
  @Input() inputType: string = 'number';
  @Input() inputMode: string = 'text';
  @Input() fullWidth: boolean = false;

  readonly disabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
