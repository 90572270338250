import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
const Package = require('../../package.json');

const VERSION_NUMBER = Package.version;

@Injectable({ providedIn: 'root' })
export class RaygunErrorHandler implements ErrorHandler {
  handleError(error: any, customData?: any) {
    RaygunWrapper.handleError(error, customData);
  }
}

export class RaygunWrapper {
  public static _initialise(): void {
    RaygunWrapper.rg4s('apiKey', (window as any).__env.raygunKey);
    RaygunWrapper.rg4s('setVersion', VERSION_NUMBER);
    RaygunWrapper.rg4s('enableCrashReporting', true);
  }

  public static handleError(error: any, customData?: any) {
    this.rg4s('send', {
      error,
      customData,
    });
  }

  public static handleEvent(event: any) {
    this.rg4s('trackEvent', event);
  }

  public static setUser(email: string, name: string): void {
    this.rg4s('setUser', {
      identifier: email,
      isAnonymous: false,
      email: email,
      firstName: name,
      fullName: name,
    });
  }

  private static rg4s(...params: any[]): void {
    rg4js(...params);
  }
}

RaygunWrapper._initialise();
