import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomServerDataSource } from '../modules/angular2-smart-table/directives/custom-server-data-source';
import { ServerDataSourceService } from './server-data-source.service';
import { SmartTableConfig } from './types/common';
import {
  StockBuyer,
  StockBuyerDelegation,
  StockBuyerProfile,
  StockBuyerUpdateModel,
} from './types/stock-buyer';
import { EnvironmentService } from './environment.service';
import { shareReplay, switchMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BuyerService {
  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService,
    private serverDataSource: ServerDataSourceService
  ) {}

  private readonly _apiUrl = `${this.environment.apiUrl}/stockbuyers`;

  getBuyersList(): Observable<any[]> {
    return this.httpClient.get<any[]>(this._apiUrl);
  }

  getBuyer(id: string): Observable<any> {
    return this.httpClient.get(`${this._apiUrl}/${id}`);
  }

  getCurrentBuyer(): Observable<StockBuyerProfile> {
    return this.httpClient.get<StockBuyerProfile>(`${this._apiUrl}/current`);
  }

  getStockBuyerGrid(
    smartTableOptions: SmartTableConfig
  ): CustomServerDataSource {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl}/grid`,
      'Stock Buyer grid request failed',
      smartTableOptions
    );
  }

  getStockBuyerById(id: number): Observable<StockBuyer> {
    return this.httpClient.get<StockBuyer>(`${this._apiUrl}/${id}`);
  }

  update(model: StockBuyerUpdateModel): Observable<any> {
    return this.httpClient.put(`${this._apiUrl}`, model);
  }

  private _refreshStockBuyerProfiles$ = new BehaviorSubject<void>(undefined);
  getStockBuyerProfiles(refresh?: boolean) {
    if (refresh) {
      this._refreshStockBuyerProfiles$.next();
    }

    return this._refreshStockBuyerProfiles$.pipe(
      switchMapTo(
        this.httpClient.get<StockBuyerProfile[]>(`${this._apiUrl}/profiles`)
      ),
      shareReplay(1)
    );
  }

  getStockBuyerDelegationGrid(smartTableOptions: SmartTableConfig) {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl}/delegations/grid`,
      'Stock Buyer Delegations grid request failed',
      smartTableOptions
    );
  }

  getStockBuyerDelegation(id: number) {
    return this.httpClient.get<StockBuyerDelegation>(
      `${this._apiUrl}/delegations/${id}`
    );
  }

  createStockBuyerDelegation(model: StockBuyerDelegation) {
    return this.httpClient.post<StockBuyerDelegation>(
      `${this._apiUrl}/delegations`,
      model
    );
  }

  updateStockBuyerDelegation(model: StockBuyerDelegation) {
    return this.httpClient.put<StockBuyerDelegation>(
      `${this._apiUrl}/delegations/${model.id}`,
      model
    );
  }

  deleteStockBuyerDelegation(id: number) {
    return this.httpClient.delete<void>(`${this._apiUrl}/delegations/${id}`);
  }
}
