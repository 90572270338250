export enum DetailsModalType {
  buyer = 1,
  stockType,
}

export enum ContractConfirmationsStatus {
  Created = 0,
  Modified = 1,
  Confirmed = 2,
  Deleted = 3,
}

export enum AllocationVersion {
  WEEKLY = 0,
  DAILY = 1,
}

export enum ConfirmationType {
  Original = 0,
  Working = 1,
}

export enum VariationStatus {
  Proposed = 0,
  Rejected = 1,
  Approved = 2,
  Completed = 3,
}
