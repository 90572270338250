import { Injectable } from '@angular/core';

/**
 * Service to wrap environment variable access
 */
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  /**
   * The base API url
   * example: http://localhost:5000/v1
   */
  public apiUrl: string = window['__env'].apiUrl;
  public versionlessApiUrl: string = this.getVersionlessUrl();

  public showEnvironmentWarning: boolean =
    window['__env'].showEnvironmentWarning;

  // #68257: Feature flag for kill numbers. Can be removed when real API is live
  public showKillNumbers: boolean = window['__env'].showKillNumbers;

  private getVersionlessUrl(): string {
    const url = window['__env'].apiUrl as string;

    return url.replace('/v1', '');
  }
}
