<nb-card>
  <nb-card-body
    *ngIf="contractData$ | async"
    class="container-body"
    [nbSpinnerStatus]="'primary'"
    [nbSpinner]="dataSource.loading | async"
  >
    <angular2-smart-table
      id="eLiveStockAngularSmartTable"
      [settings]="tableSettings"
      [source]="dataSource"
      (edit)="onEdit($event)"
    >
    </angular2-smart-table>
  </nb-card-body>
</nb-card>
