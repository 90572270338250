import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RaygunWrapper } from '../app.raygun.setup';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { LocalStorageKey } from '@shared/services/types/local-storage-key';
import { StockBuyerProfile } from '@shared/services/types/stock-buyer';

/**
 * Sample taken from
 * @see https://github.com/jeroenheijmans/sample-angular- \
 * oauth2-oidc-with-auth-guards/blob/master/src/app/core/auth.service.ts
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private stockBuyerProfile: StockBuyerProfile = undefined;
  private hasSetupRaygun = false;

  public getStockBuyerProfile() {
    return this.stockBuyerProfile;
  }

  public setStockBuyerProfile(stockBuyerProfile: StockBuyerProfile) {
    this.stockBuyerProfile = stockBuyerProfile;

    if (stockBuyerProfile) {
      this.localStorageService.set(
        LocalStorageKey.DefaultStockBuyerProfile,
        this.stockBuyerProfile
      );
    } else {
      this.localStorageService.remove(LocalStorageKey.DefaultStockBuyerProfile);
    }
  }

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/should-login');
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    if (!environment.production) {
      this.oauthService.events.subscribe((event) => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuthErrorEvent Object:', event);
        }
      });
    }

    this.stockBuyerProfile = this.localStorageService.get(
      LocalStorageKey.DefaultStockBuyerProfile
    );

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
      if (this.identityClaims && !this.hasSetupRaygun) {
        this.hasSetupRaygun = true;
        const email = this.identityClaims.preferred_username;
        const name = this.identityClaims.name;
        RaygunWrapper.setUser(email, name);
      }
    });

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((e) => this.navigateToLoginPage());
  }

  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    this.oauthService.setupAutomaticSilentRefresh();
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => {
          if (!this.oauthService.hasValidAccessToken()) {
            return this.oauthService.tryLogin();
          }
        })

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve(null);
          }
          return Promise.reject();
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            const stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              decodeURIComponent(stateUrl);
            }
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout(noRedirectToLogoutUrl?: boolean) {
    this.oauthService.logOut(noRedirectToLogoutUrl);
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get loggedInUserEmail(): string {
    return this.identityClaims.preferred_username;
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims(): IdentityClaims {
    return this.oauthService.getIdentityClaims() as IdentityClaims;
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}

export interface IdentityClaims {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  roles: string;
  sub: string;
  tid: string;
  uti: string;
  ver: string;
}
