import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ContractDeclarationService {
  private _declarations$ = new ReplaySubject<ContractDeclaration[]>(0);

  constructor(
    private readonly environment: EnvironmentService,
    private readonly httpClient: HttpClient
  ) {}

  private apiUrl(): string {
    return `${this.environment.apiUrl}/digital-contracts/declarations`;
  }

  /**
   * Gets obserable of contract declarations the current user has assigned to them.
   * Note: this observable stays alive and will emit changes when contract declaration statuses change
   */
  public getContractDeclarations(): Observable<ContractDeclaration[]> {
    this.loadDeclarations();
    return this._declarations$.asObservable();
  }

  /**
   * Signs a contract declaration for the current user.
   * Note: will load the updated set of contract declarations and emit through the getContractDeclarations observable
   */
  public makeDeclaration(id: number): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl()}/${id}/sign`, {})
      .pipe(finalize(() => this.loadDeclarations()));
  }

  private loadDeclarations(): void {
    this.httpClient
      .get<ContractDeclaration[]>(`${this.apiUrl()}/required`)
      .subscribe((declarations) => this._declarations$.next(declarations));
  }
}

export interface ContractDeclaration {
  id: number;
  contractName: string;
  declaration: string;
  supplier: string;
}
