import { Component, Input } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-dialog',
  styleUrls: ['confirmation-dialog.component.scss'],
  template: `
    <nb-card>
      <nb-card-header>
        <h6>{{ title }}</h6>
        <nb-icon icon="close" (click)="cancel()"></nb-icon>
      </nb-card-header>

      <nb-card-body>
        {{ message }}
      </nb-card-body>

      <nb-card-footer>
        <button
          nbButton
          *ngIf="showYesButton"
          status="primary"
          (click)="continue()"
        >
          Yes
        </button>
        <button nbButton (click)="cancel()">{{ cancelButonText }}</button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class ConfirmationDialogComponent {
  @Input() title = 'Delete item';
  @Input() message = 'Are you sure you want to delete it?';
  @Input() cancelButonText = 'Cancel';
  @Input() showYesButton = true;

  constructor(private dialogRef: NbDialogRef<ConfirmationDialogComponent>) {}

  continue() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
