import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { EnvironmentIntegrationService } from '../../../shared/services/environment-integration.service';

@Component({
  selector: 'ngx-environment-warning',
  styleUrls: ['./environment-warning.component.scss'],
  templateUrl: './environment-warning.component.html',
})
export class EnvironmentWarningComponent implements OnInit {
  public shouldShow: boolean;

  public smsCardType$: Observable<string>;
  public smsWording$: Observable<string>;

  public emailCardType$: Observable<string>;
  public emailWording$: Observable<string>;

  constructor(private _smsService: EnvironmentIntegrationService) {}

  public ngOnInit(): void {
    this.shouldShow = this._smsService.isEnvironmentWarningEnabled();

    if (this.shouldShow) {
      const isSmsEnabled$ = this._smsService.isSmsEnabled().pipe(shareReplay());
      this.smsCardType$ = isSmsEnabled$.pipe(
        map((isEnabled) => (isEnabled ? 'warning' : 'info'))
      );
      this.smsWording$ = isSmsEnabled$.pipe(
        map((isEnabled) =>
          isEnabled ? 'SMS integration is on' : 'SMS integration is off'
        )
      );

      const isEmailEnabled$ = this._smsService
        .isEmailEnabled()
        .pipe(shareReplay());
      this.emailCardType$ = isEmailEnabled$.pipe(
        map((isEnabled) => (isEnabled ? 'warning' : 'info'))
      );
      this.emailWording$ = isEmailEnabled$.pipe(
        map((isEnabled) =>
          isEnabled ? 'Email integration is on' : 'Email integration is off'
        )
      );
    }
  }
}
