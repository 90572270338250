<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img
      class="logo-img"
      src="../../../assets/images/anzco-logo-blue.svg"
      alt="Anzco Logo"
    />
    <a class="logo" href="#" (click)="navigateHome()">
      <span>{{ getTitle() }}</span>
    </a>
  </div>
</div>

<div class="header-container environment-warning-container">
  <ngx-environment-warning></ngx-environment-warning>
</div>

<div class="header-container" *ngIf="userMenu$ | async as userMenu">
  <nb-actions size="small">
    <nb-action>
      <nb-user
        [nbContextMenu]="userMenu"
        [name]="getUserName()"
        [badgeText]="totalNotifications$ | async"
        badgePosition="bottom right"
        badgeStatus="primary"
      ></nb-user>
    </nb-action>
  </nb-actions>
</div>
