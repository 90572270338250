<!-- todo move to the separate component because this header's items exists in a lot of places -->
<div class="contract-info">
  <div class="info-item" *ngIf="beginDate">
    <span class="description">Week Beginning:</span>
    <span class="value">
      {{ beginDate | date: "dd/MM/yyyy" }}
    </span>
  </div>
  <div class="info-item" *ngIf="beginDate">
    <span class="description">Week No:</span>
    <span class="value">{{ beginDate | week }}</span>
  </div>
  <div class="info-item">
    <span class="description">Status:</span>
    <span class="value">{{ currentContract?.status }}</span>
  </div>
</div>

<nb-card>
  <nb-card-body [nbSpinner]="dataLoading$ | async">
    <angular2-smart-table
      id="eLiveStockAngularSmartTable"
      [settings]="contractTableSettings"
      [source]="contractDataSource"
    ></angular2-smart-table>
  </nb-card-body>
</nb-card>

<div class="grid-controls">
  <button
    *nbIsGranted="['read', 'suppliercontracts']"
    [disabled]="
      (roleCanUnconfirm$ | async) !== true || confirmationStatus !== 'Confirmed'
    "
    nbButton
    status="primary"
    [nbSpinner]="dataSaving$ | async"
    (click)="onUpdate(confirmationStatuses.Modified)"
  >
    <nb-icon icon="save-outline"></nb-icon>
    <span>UNCONFIRM</span>
  </button>
  <button
    *nbIsGranted="['update', 'suppliercontracts']"
    [disabled]="confirmationStatus === 'Confirmed'"
    nbButton
    status="primary"
    [nbSpinner]="dataSaving$ | async"
    (click)="onUpdate(confirmationStatuses.Modified)"
  >
    <nb-icon icon="plus-circle-outline"></nb-icon>
    <span>UPDATE</span>
  </button>
  <button
    *nbIsGranted="['update', 'suppliercontracts']"
    [disabled]="confirmationStatus === 'Confirmed'"
    nbButton
    status="primary"
    [nbSpinner]="dataSaving$ | async"
    (click)="onUpdate(confirmationStatuses.Confirmed)"
  >
    <nb-icon icon="save-outline"></nb-icon>
    <span>CONFIRM</span>
  </button>
  <button nbButton status="info" (click)="goBack()">
    <nb-icon icon="save-outline"></nb-icon>
    <span>BACK TO LIST</span>
  </button>
</div>
