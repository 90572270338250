import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomServerDataSource } from '../modules/angular2-smart-table/directives/custom-server-data-source';
import { ServerDataSourceService } from './server-data-source.service';
import { SmartTableConfig } from './types/common';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class PremiumRequestService {
  private totalPremiumRequests$ = new ReplaySubject<number>(0);

  constructor(
    private _environmentService: EnvironmentService,
    private httpClient: HttpClient,
    private serverDataSource: ServerDataSourceService
  ) {}

  /**
   * Gets obserable of total number of premium requests for the current user.
   * Note: this observable stays alive and will emit changes when premium requests are actioned
   */
  public getTotalPremiumRequests(): Observable<number> {
    this.loadTotalPremiumRequests();
    return this.totalPremiumRequests$.asObservable();
  }

  private loadTotalPremiumRequests(): void {
    const params = new HttpParams()
      .append('filterBystatus', 1)
      .append('pageNumber', 1)
      .append('pageSize', 1);
    this.httpClient
      .get<{ totalCount: number }>(`${this._apiUrl()}/premium-requests/grid`, {
        params,
      })
      .subscribe((grid) => this.totalPremiumRequests$.next(grid.totalCount));
  }

  public approve(id: number): Observable<any> {
    return this.httpClient
      .post<void>(`${this._apiUrl()}/premium-requests/approve/${id}`, {})
      .pipe(tap(() => this.loadTotalPremiumRequests()));
  }

  public amend(id: number, items: any[]): Observable<any> {
    return this.httpClient
      .post<void>(`${this._apiUrl()}/premium-requests/amend/${id}`, items)
      .pipe(tap(() => this.loadTotalPremiumRequests()));
  }

  public reject(id: number): Observable<any> {
    return this.httpClient
      .post<void>(`${this._apiUrl()}/premium-requests/reject/${id}`, {})
      .pipe(tap(() => this.loadTotalPremiumRequests()));
  }

  getPremiumRequestGridDataSource(
    smartTableOptions: SmartTableConfig
  ): CustomServerDataSource {
    return this.serverDataSource.getServerDataSource(
      `${this._apiUrl()}/premium-requests/grid`,
      '',
      smartTableOptions
    );
  }

  private _apiUrl(): string {
    return `${this._environmentService.apiUrl}/stockadvices`;
  }
}
