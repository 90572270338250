import { Injectable } from '@angular/core';
import { SmartTableConfig } from '../../../services/types/common';
@Injectable({
  providedIn: 'root',
})
export class SmartTableConfigPersistenceService {
  private _tableConfig = new Map<string, SmartTableConfig>();

  public get(key: string): SmartTableConfig {
    return this._tableConfig.get(key) ?? new SmartTableConfig();
  }

  public set(key: string, value: SmartTableConfig): void {
    this._tableConfig.set(key, value);
  }
}
