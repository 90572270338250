import { Injectable } from '@angular/core';

import { NbToastrService } from '@nebular/theme';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private toastrServcie: NbToastrService) {}

  private readonly defaultErrorTitle =
    'An Error has been detected and logged - Please contact the help desk';

  handleAction<T>(
    action: string,
    entity: string
  ): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        tap(() => this.showSuccess(`${entity} successfully ${action}d`)),
        catchError((err) => {
          this.showError(`${entity} failed to ${action}`);
          return throwError(err);
        })
      );
  }

  handleError<T>(title?: string): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        catchError((err: HttpErrorResponse) => {
          if (title) this.showError(title, getErrorFromHttpResponse(err));
          else this.showError(getErrorFromHttpResponse(err));
          return throwError(() => err);
        })
      );
  }

  showSuccess(title, message?) {
    return this.toastrServcie.show(message, title, {
      status: 'success',
      icon: 'checkmark-circle-2',
    });
  }

  showError(title?, message?) {
    const errorTitle = title || this.defaultErrorTitle;
    return this.toastrServcie.show(message, errorTitle, {
      status: 'danger',
      icon: 'close-circle',
      duration: 0,
    });
  }

  toast400Error<T>(): (source: Observable<T>) => Observable<T> {
    const error = (err: HttpErrorResponse) =>
      this.showError(getErrorFromHttpResponse(err));

    return (source: Observable<T>) => source.pipe(tap({ error }));
  }
}

const getErrorFromHttpResponse = (err: HttpErrorResponse) => {
  const details = (err.error?.details || err.error?.errors) as {
    [key: string]: string[];
  };

  if (details) {
    const firstErrorKey = Object.keys(details)[0];
    const errors = details[firstErrorKey].join(',');
    return `Errors with ${firstErrorKey}: ${errors}`;
  }

  return err.error?.message || err.message;
};
