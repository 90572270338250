import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { format } from 'date-fns';

type NgxDateFormats =
  | 'shortYear'
  | 'dayMonthDate'
  | 'day'
  | 'dayMonthYear'
  | 'monthDayTime'
  | 'timeDate'
  | 'hour' // e.g. 8am, 3pm
  | 'shortDate24time';

const ngxDateFormatsLookup: { [key in NgxDateFormats]: string } = {
  dayMonthYear: 'dd/MM/yyyy',
  shortYear: 'y',
  dayMonthDate: 'EEEE, MMMM d',
  day: 'EEEE',
  monthDayTime: 'MMM d, h:mm a',
  timeDate: 'h:mm a dd/MM/yyyy',
  shortDate24time: 'dd/MM, H:mm',
  hour: 'NOTE: use datefns as no "am/pm" support by angular date pipe',
};

/**
 * Custom Date pipe leveraging Angular's DatePipe but extending it to provide more date formats.
 */
@Pipe({
  name: 'ngxDate',
})
export class NgxDatePipe implements PipeTransform {
  constructor(private _datePipe: DatePipe) {}

  /**
   * @param value - The date to transform
   * @param displayFormat - The format to apply to the date
   */
  transform(
    value: string | Date,
    displayFormat?: NgxDateFormats | string
  ): string {
    if (displayFormat == 'hour') {
      return format(new Date(value), 'haaa');
    }

    const dateFormat = ngxDateFormatsLookup[displayFormat] || displayFormat;
    return this._datePipe.transform(value, dateFormat);
  }
}
