import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from './toastr.service';
import { CustomServerDataSource } from '../modules/angular2-smart-table/directives/custom-server-data-source';
import { SmartTableConfig } from './types/common';
import { RaygunErrorHandler } from '../../app.raygun.setup';

@Injectable({
  providedIn: 'root',
})
export class ServerDataSourceService {
  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastrService,
    protected logger: RaygunErrorHandler
  ) {}

  getServerDataSource(
    uri: string,
    errorMessage: string,
    smartTableOptions?: SmartTableConfig
  ): CustomServerDataSource {
    return new CustomServerDataSource(
      this.httpClient,
      {
        endPoint: uri,
        totalKey: 'totalCount',
        dataKey: 'items',
        pagerPageKey: 'pageNumber',
        pagerLimitKey: 'pageSize',
        filterFieldKey: 'filterBy#field#',
        sortFieldKey: 'sortBy',
        sortDirKey: 'orderBy',
      },
      this.toastrService,
      this.logger,
      smartTableOptions?.filterConfig,
      smartTableOptions?.sortConfig,
      smartTableOptions?.pagingConfig,
      errorMessage
    );
  }
}
