import { Component, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DefaultFilter } from 'angular2-smart-table';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'ngx-custom-input-filter',
  template: `
    <input
      [ngClass]="inputClass"
      [formControl]="inputControl"
      nbInput
      fullWidth
      class="form-control"
      type="{{ _inputType }}"
      placeholder="{{ column.title }}"
    />
  `,
  styleUrls: ['./custom-input-filter.component.scss'],
})
export class CustomInputFilterComponent
  extends DefaultFilter
  implements OnInit, OnChanges
{
  inputControl = new FormControl();
  _inputType: string;
  private lastDebouncedValue: any;

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.query) {
      this.inputControl.setValue(this.query);
    }
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.delay),
        tap((value) => (this.lastDebouncedValue = value))
      )
      .subscribe((value: string) => {
        this.query = this.inputControl.value;
        this.setFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.column) {
      this._inputType = changes.column.currentValue.type || 'string';
    }

    if (
      changes.query &&
      this.lastDebouncedValue !== changes.query.currentValue
    ) {
      this.inputControl.setValue(changes.query.currentValue);
    }
  }
}
