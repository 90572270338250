import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from '@shared/services/toastr.service';
import { RaygunErrorHandler } from 'app/app.raygun.setup';
import { Observable, catchError, throwError } from 'rxjs';

/**
 * Our API sits behind a WAF which runs OWASP rules, and returns a 403 response for requests that the rules catch
 * The problem is it can be very temperamental with text in the request payload.
 * For example, this will trigger an OWASP rule and result in a 403 response: "$4/head premium please"
 * And this will make it through and hit our API without issue: "please add $4/head premium"
 *
 * In order to make this problem easier to diagnose, we:
 * - explicitly tell the user it was a WAF issue
 * - log the error in raygun, with the payload that caused the rule to trigger
 */
@Injectable()
export class WafErrorInterceptor implements HttpInterceptor {
  constructor(
    private logger: RaygunErrorHandler,
    private toaster: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const potentialWafRequest = ['PUT', 'POST'].includes(request.method);

        // Real WAF issues will have status of 0 as it results in an additional CORS issue
        const potentialWafResponse = error.status === 0;
        if (potentialWafRequest && potentialWafResponse) {
          this.logger.handleError(error, {
            wafError: true,
            requestBody: request.body,
          });

          this.toaster.showError(
            'Potential WAF Issue',
            'Our security probably hit a false positive. Please let the ANZCO support know you have hit a WAF issue. A possible fix would be trying to reword any text you have entered'
          );
        }

        return throwError(() => error);
      })
    );
  }
}
