import { Pipe, PipeTransform } from '@angular/core';
import { SupplierProperty } from '../services/types/supplier';
import { fullAddress, ThreeAddresses } from '../utils/address-utilities';

/**
 * Pipe to create a display name for a supplier's property.
 */
@Pipe({
  name: 'supplierPropertyName',
})
export class SupplierPropertyNamePipe implements PipeTransform {
  /**
   * Returns a display name for a supplier's property.
   * @param property - The property to get the name for.
   */
  transform(property: SupplierProperty): string {
    const { address1, fullName } = property?.contact;
    const address = fullAddress(<ThreeAddresses>{ address1 }, true).trim();

    return [fullName, address, property.active ? null : 'INACTIVE']
      .filter((segment) => !!segment)
      .join(' - ');
  }
}
