import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ConfirmationDialogComponent } from '../../../../../@theme/components/confirmation-dialog/confirmation-dialog.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { NbAccessChecker, NbRoleProvider } from '@nebular/security';
import { Roles } from '../../../../../@auth/roles.enum';
import { ColumnType, Settings } from 'angular2-smart-table';
import { ContractConfirmationsStatus } from '@shared/services/types/enums';
import { InputViewComponent } from '@shared/modules/angular2-smart-table/components/input-view/input-view.component';
import { Contract } from '@shared/services/types/supplier';
import { SupplierService } from '@shared/services/supplier.service';
import { ToastrService } from '@shared/services/toastr.service';

@Component({
  selector: 'ngx-contract-confirmation-details',
  templateUrl: './change-contract-confirmation.component.html',
  styleUrls: ['./change-contract-confirmation.component.scss'],
})
export class ChangeContractConfirmationComponent implements OnInit {
  confirmationStatuses: any = ContractConfirmationsStatus;
  confirmationStatus: string;

  contractTableSettings: Settings = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      contract: {
        title: 'Contract',
        filter: false,
        width: '10%',
      },
      client: {
        title: 'Client',
        filter: false,
        width: '15%',
      },
      stockGroup: {
        title: 'Stock Group',
        filter: false,
        width: '10%',
      },
      program: {
        title: 'Program',
        filter: false,
        width: '20%',
      },
      status: {
        title: 'Status',
        filter: false,
        width: '10%',
      },
      tally: {
        title: 'Tally',
        filter: false,
        width: '5%',
      },
      confirm: {
        title: 'Confirm',
        filter: false,
        type: ColumnType.Custom,
        width: '5%',
        renderComponent: InputViewComponent,
        onComponentInitFunction: (instance) => {
          instance.rowColumnName = 'confirm';
          instance.inputMode = 'numeric';
          instance.inputType = 'number';
          setTimeout(() => this.disableIfCannotEdit(instance), 0);
        },
      },
      notes: {
        title: 'Notes',
        filter: false,
        type: ColumnType.Custom,
        width: '25%',
        renderComponent: InputViewComponent,
        onComponentInitFunction: (instance) => {
          instance.rowColumnName = 'notes';
          instance.inputType = 'string';
          instance.fullWidth = true;
          setTimeout(() => this.disableIfCannotEdit(instance), 0);
        },
      },
    },
  };

  contractDataSource = [];
  currentContract: Contract;
  beginDate: Date;
  dataLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  dataSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly selectedContractId = Number(
    this.activatedRoute.snapshot.paramMap.get('id')
  );
  private readonly slaughterPeriodId =
    this.activatedRoute.snapshot.queryParams['slaughterPeriodId'];

  readonly roleCanUnconfirm$ = this.roleProvider
    .getRole()
    .pipe(
      map(
        (roles) =>
          roles.includes(Roles.SystemAdministrator) ||
          roles.includes(Roles.LivestockRegionalManager)
      )
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private supplierService: SupplierService,
    private dialogService: NbDialogService,
    private toastrService: ToastrService,
    private accessChecker: NbAccessChecker,
    private roleProvider: NbRoleProvider
  ) {}

  public disableIfCannotEdit(instance) {
    if (!instance.rowData.canEdit) {
      instance.disabled.next(true);
    }
  }

  ngOnInit(): void {
    if (!this.slaughterPeriodId) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
      return;
    }

    combineLatest([
      this.supplierService.getSupplierContracts(this.prepareContractRequest()),
      this.accessChecker.isGranted('update', 'suppliercontracts'),
    ])
      .pipe(
        filter(
          ([contract, canEdit]: [Contract[], boolean]) =>
            contract[0].contractLines != null
        )
      )
      .subscribe(([contract, canEdit]: [Contract[], boolean]) => {
        this.currentContract = contract[0];

        const dataSource = [];

        contract[0].contractLines.forEach((line) => {
          const total = line.items.reduce(
            (sum, item) => item.weekTally + sum,
            0
          );
          line.items.forEach((lineItem) => {
            this.beginDate = lineItem.slaughterPeriod.beginDate
              ? lineItem.slaughterPeriod.beginDate
              : this.beginDate;
            this.confirmationStatus = lineItem.confirmStatus;

            dataSource.push({
              id: contract[0].id,
              contract: contract[0].code,
              lineId: lineItem.id,
              client: `${contract[0].supplier.name}`,
              total: total,
              stockGroup: line.stockGroup.name,
              program: contract[0].supplyProgram.name,
              status: lineItem.confirmStatus,
              tally: lineItem.weekTally,
              confirm:
                lineItem.confirmStatus === 'Created'
                  ? lineItem.weekTally
                  : lineItem.confirmTally,
              notes: lineItem.notes,
              canEdit: canEdit && lineItem.confirmStatus !== 'Confirmed',
            });
          });
        });
        this.contractDataSource = dataSource;
        this.dataLoading$.next(false);
      });
  }

  onUpdate(confirmStatusId: number) {
    if (
      this.confirmationStatus === 'Confirmed' &&
      confirmStatusId === this.confirmationStatuses.Modified
    ) {
      this.updateContracts(confirmStatusId, true);
      return;
    }

    const alertFactor = 0.9;
    const minimumTally = 0;

    let invalidConfirmItem = false;
    let lowConfirmItem = false;

    this.contractDataSource.forEach((contractItem) => {
      if (
        Number.isNaN(parseInt(contractItem.confirm, 10)) ||
        contractItem.confirm < minimumTally ||
        contractItem.confirm > contractItem.tally
      ) {
        invalidConfirmItem = true;
      } else if (contractItem.confirm < contractItem.tally * alertFactor) {
        lowConfirmItem = true;
      }
    });

    if (invalidConfirmItem) {
      this.openContractUpdateInvalidModal();
    } else if (lowConfirmItem) {
      this.openContractUpdateAlertModal(confirmStatusId);
    } else {
      this.updateContracts(confirmStatusId);
    }
  }

  private openContractUpdateInvalidModal() {
    this.dialogService.open(ConfirmationDialogComponent, {
      context: {
        title: 'Invalid Confirm Value',
        message:
          'One or more contract items has an invalid Confirm value. Please correct the value and try again.',
        showYesButton: false,
      },
    });
  }

  private openContractUpdateAlertModal(confirmStatusId: number) {
    const modalResult = this.dialogService.open(ConfirmationDialogComponent, {
      context: {
        title: 'Low Confirm Value',
        message:
          'One or more contract items has a Confirm value of less than ' +
          '90% of its tally. Do you still want to continue?',
      },
    });

    modalResult.onClose.subscribe((resultYes) => {
      if (resultYes) {
        this.updateContracts(confirmStatusId);
      }
    });
  }

  private updateContracts(confirmStatusId: number, isUnconfirm = false) {
    this.dataSaving$.next(true);

    this.supplierService
      .updateSupplierContract(this.prepareContractUpdate(confirmStatusId))
      .pipe(finalize(() => this.dataSaving$.next(false)))
      .subscribe(
        () => {
          if (isUnconfirm) {
            this.toastrService.showSuccess(
              'Contract was successfully un-confirmed.'
            );
          } else {
            this.toastrService.showSuccess(
              'Contract was successfully updated.'
            );
          }
          this.router.navigate(['../../'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          this.toastrService.showError();
        }
      );
  }

  goBack() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
    });
  }

  prepareContractUpdate(confirmStatusId: number) {
    return this.contractDataSource.map((contractItem) => {
      return {
        id: contractItem.lineId,
        confirmTally: contractItem.confirm || 0,
        notes: contractItem.notes,
        confirmStatus: confirmStatusId,
      };
    });
  }

  private prepareContractRequest(): any {
    return {
      slaughterPeriodId: this.slaughterPeriodId,
      contractId: this.selectedContractId,
    };
  }
}
