import { HttpClient } from '@angular/common/http';
import { ServerDataSource } from 'angular2-smart-table';
import { ServerSourceConf } from 'angular2-smart-table/lib/lib/data-source/server/server-source.conf';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { ToastrService } from '../../../services/toastr.service';
import {
  SmartTableFilterConfig,
  SmartTablePagingConfig,
  SmartTableSortConfig,
} from '../../../services/types/common';
import { RaygunErrorHandler } from 'app/app.raygun.setup';

export class CustomServerDataSource extends ServerDataSource {
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public data: any[];

  private errorMessage: string;

  private previousFilterValue: SmartTableFilterConfig[] = null;

  private readonly defaultErrorMessage: string = 'Grid data getting failed';

  constructor(
    protected http: HttpClient,
    conf: ServerSourceConf | {} = {},
    protected toasterService: ToastrService,
    protected logger: RaygunErrorHandler,
    private outerFilterData?: SmartTableFilterConfig[],
    private outerSortData?: SmartTableSortConfig[],
    private outerPagingData?: SmartTablePagingConfig,
    errorMessage?: string
  ) {
    super(http, conf);
    this.errorMessage = errorMessage || this.defaultErrorMessage;

    if (outerFilterData) {
      this.previousFilterValue = outerFilterData;
    }
  }

  // Override
  getElements(): Promise<any> {
    return this.requestElements()
      .pipe(
        map((res) => {
          try {
            this.lastRequestCount = this.extractTotalFromResponse(res);
          } catch (error) {
            this.logger.handleError(error, {
              apiResponse: JSON.stringify(res),
              caughtClass: 'CustomServerDataSource',
              caughtMethod: 'getElements',
              caughtCall: 'extractTotalFromResponse',
            });
            throw error;
          }
          try {
            this.data = this.extractDataFromResponse(res);
          } catch (error) {
            this.logger.handleError(error, {
              apiResponse: JSON.stringify(res),
              lastRequestCount: this.lastRequestCount,
              caughtClass: 'CustomServerDataSource',
              caughtMethod: 'getElements',
              caughtCall: 'extractDataFromResponse',
            });
            throw error;
          }

          this.loading.next(false);
          return this.data;
        }),
        debounceTime(500),
        catchError((error) => {
          this.logger.handleError(error, {
            caughtClass: 'CustomServerDataSource',
            caughtMethod: 'getElements',
            caughtCall: 'catchError',
          });
          this.toasterService.showError(this.errorMessage);
          return of([]);
        })
      )
      .toPromise();
  }

  protected emitOnChanged(action: string): void {
    if (action === 'refresh') {
      if (this.outerFilterData?.length) {
        this.filterConf.filters = JSON.parse(
          JSON.stringify(this.outerFilterData)
        );
        this.outerFilterData = null;
      }
      if (this.outerSortData?.length) {
        this.sortConf = JSON.parse(JSON.stringify(this.outerSortData));
        this.outerSortData = null;
      }
      if (this.outerPagingData) {
        this.pagingConf = JSON.parse(JSON.stringify(this.outerPagingData));
        this.outerPagingData = null;
      }
    }

    // this checks needed because angular2-smart-table has a bug when the user pass the filter data outside,
    // In this case table create additional request for every passed filter parameter. So the first "if" above initiates
    // the inner data source filterConf parameter, the second if (below) prevents the creation of duplicate request.
    if (
      action === 'filter' &&
      JSON.stringify(this.filterConf.filters) ===
        JSON.stringify(this.previousFilterValue)
    ) {
      return;
    }

    this.loading.next(true);

    this.getElements().then((elements) => {
      return this.onChangedSource.next({
        action: action,
        elements: elements,
        paging: this.pagingConf,
        filter: this.filterConf,
        sort: this.sortConf,
      });
    });

    this.previousFilterValue = JSON.parse(
      JSON.stringify(this.filterConf.filters)
    );
  }
}
