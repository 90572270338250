import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxNumberOrDefault' })
export class NumberOrDefaultPipe implements PipeTransform {
  transform(input: number, defaultValue: string = ''): string {
    if (input == null) {
      return defaultValue;
    }
    return new DecimalPipe('en').transform(input);
  }
}
