export enum LocalStorageKey {
  DefaultStockBuyerProfile = '_defaultStockBuyerProfile',
  DefaultSupplierId = '_defaultSupplierId',
  DefaultTruckingCompanyId = '_defaultCompanyId',

  /**
   * Refers to the Uniworks/Microservice ID not eLivestock's primary key
   */
  DefaultSlaughterPlantExternalId = '_defaultSlaughterPlantExternalId',
}
