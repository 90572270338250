import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentIntegrationService {
  public smsStatus = new Subject<boolean>();
  public emailStatus = new Subject<boolean>();

  private emailUrl = `${this.environmentService.apiUrl}/email/enabled`;
  private smsUrl = `${this.environmentService.apiUrl}/sms/enabled`;

  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  public isEnvironmentWarningEnabled(): boolean {
    return this.environmentService.showEnvironmentWarning;
  }

  public isSmsEnabled(): Observable<boolean> {
    this.httpClient.get<boolean>(this.smsUrl).subscribe(
      (enabled) => this.smsStatus.next(enabled),
      (error) => this.smsStatus.error(error)
    );

    return this.smsStatus.asObservable();
  }

  public isEmailEnabled(): Observable<boolean> {
    this.httpClient.get<boolean>(this.emailUrl).subscribe(
      (enabled) => this.emailStatus.next(enabled),
      (error) => this.emailStatus.error(error)
    );

    return this.emailStatus.asObservable();
  }

  public toggleEmail(enable: boolean): Observable<void> {
    const request = enable
      ? this.httpClient.post(this.emailUrl, {})
      : this.httpClient.delete(this.emailUrl);

    return request.pipe(
      tap(() => this.isEmailEnabled()),
      mapTo(null)
    );
  }

  public toggleSms(enable: boolean): Observable<void> {
    const request = enable
      ? this.httpClient.post(this.smsUrl, {})
      : this.httpClient.delete(this.smsUrl);

    return request.pipe(
      tap(() => this.isSmsEnabled()),
      mapTo(null)
    );
  }
}
