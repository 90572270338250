import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { Roles } from '../../@auth/roles.enum';
import { combineLatest, NEVER, Observable, of, timer } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ContractDeclarationService } from './contract-declaration.service';
import { PremiumRequestService } from './premium-request.service';

/**
 * An item to display underneath the user menu
 */
export interface NotificationItem {
  title: string;
  link: string;
  icon: string;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly contractDeclarationService: ContractDeclarationService,
    private readonly premiumRequestService: PremiumRequestService,
    private roleProvider: NbRoleProvider
  ) {}

  /**
   * Gets all notification items to display under the user menu
   * Currently made up of:
   * - total number of outstanding contract declarations
   * - total number of premium requests
   */
  public getAll(): Observable<NotificationItem[]> {
    return combineLatest([
      this.getDeclarationsNotification(),
      this.getPremiumRequestsNotification(),
    ]).pipe(
      map(([declarationNotification, premiumRequestNotification]) =>
        [declarationNotification, premiumRequestNotification].filter(Boolean)
      )
    );
  }

  public getTimer(): Observable<number> {
    const halfAnHour = 1000 * 60 * 30;
    return timer(0, halfAnHour);
  }

  private getDeclarationsNotification(): Observable<NotificationItem> {
    // Underlying contract declaration service will emit updated data when declarations are submitted
    // so don't need to worry about refreshing it ourselves here
    return this.getTimer().pipe(
      // after every interval, call the declaration api
      switchMap(() =>
        this.contractDeclarationService
          .getContractDeclarations()
          // catch any api error and return a NEVER observable
          // this will keep the observable alive, so we can continue polling the api
          // and also keeps the previous successful api value
          .pipe(catchError(() => NEVER))
      ),
      map((declarations) => {
        if (declarations.length === 0) {
          return null;
        }
        return getDeclarationNotificationItem(declarations.length);
      }),
      startWith(null as NotificationItem)
    );
  }

  private getPremiumRequestsNotification(): Observable<NotificationItem> {
    // Underlying contract declaration service will emit updated data when declarations are submitted
    // so don't need to worry about refreshing it ourselves here
    const notificaiton$ = this.getTimer().pipe(
      // after every interval, call the premium request api
      switchMap(() =>
        this.premiumRequestService
          .getTotalPremiumRequests()
          // catch any api error and return a NEVER observable
          // this will keep the observable alive, so we can continue polling the api
          // and also keeps the previous successful api value
          .pipe(catchError(() => NEVER))
      ),
      map((totalOutstanding) => {
        if (totalOutstanding === 0) {
          return null;
        }
        return getPremiumRequestNotificationItem(totalOutstanding);
      }),
      startWith(null as NotificationItem)
    );

    return this.roleProvider.getRole().pipe(
      filter<string[]>(Boolean),
      map((roles) => roles.includes(Roles.LivestockRegionalManager)),
      switchMap((hasPermission) => (hasPermission ? notificaiton$ : of(null)))
    );
  }
}

export function getDeclarationNotificationItem(
  total: number
): NotificationItem {
  return {
    title: `${total} New declarations`,
    link: '/pages/buyer-management/contract-declarations/select',
    icon: 'bell-outline',
    total,
  };
}

export function getPremiumRequestNotificationItem(
  total: number
): NotificationItem {
  return {
    title: `${total} Premium requests`,
    link: '/pages/livestock-management/premiums',
    icon: 'bell-outline',
    total,
  };
}
