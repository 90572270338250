import { Injectable } from '@angular/core';
import { decodeJwtPayload } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, switchMap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleProvider extends NbRoleProvider {
  private readonly ROLE_FIELD = window['__env'].authConfig.roleClaim;

  constructor(private authService: AuthService) {
    super();
  }

  getRole(): Observable<string[]> {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      map((x) => {
        if (!this.authService.hasValidToken()) {
          return [];
        }

        const accessToken = decodeJwtPayload(this.authService.accessToken);
        return accessToken[this.ROLE_FIELD] || [];
      })
    );
  }
}
