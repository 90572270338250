import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ColumnType, Settings } from 'angular2-smart-table';
import { SmartTableConfigPersistenceDirective } from '@shared/modules/angular2-smart-table/directives/smart-table-config-persistence';
import { SupplierService } from '@shared/services/supplier.service';
import { SlaughterPeriodService } from '@shared/services/slaughter-period-service';
import { BuyerService } from '@shared/services/buyer.service';
import { ToastrService } from '@shared/services/toastr.service';
import {
  SmartTableConfig,
  SmartTableFilterConfig,
} from '@shared/services/types/common';
import { CustomInputFilterComponent } from '@shared/modules/angular2-smart-table/components/input-filter/custom-input-filter.component';
import { CustomServerDataSource } from '@shared/modules/angular2-smart-table/directives/custom-server-data-source';
import { SmartTableConfigPersistenceService } from '@shared/modules/angular2-smart-table/services/smart-table-config-persistence.service';
import { CustomSelectFilterComponent } from '@shared/modules/angular2-smart-table/components/custom-select-filter/custom-select-filter.component';

@Component({
  selector: 'ngx-contract-confirmation',
  templateUrl: './contract-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractConfirmationComponent extends SmartTableConfigPersistenceDirective {
  dataSource: CustomServerDataSource;
  tableSettings: Settings;

  readonly buyers$ = this.buyerService.getBuyersList().pipe(
    map((res) => {
      return res.map((buyer) => {
        return { value: buyer.id, title: buyer.contact.fullName };
      });
    })
  );

  readonly slaughterPeriods$ = this.slaughterPeriodService
    .getSlaughterPeriods(this.getContractDate(), '')
    .pipe(
      map((res) => {
        return res.map((period) => {
          return {
            value: period.id,
            title: moment(period.beginDate).format('DD-MMM-yyyy'),
          };
        });
      })
    );

  readonly contractData$ = combineLatest([
    this.buyers$,
    this.slaughterPeriods$,
  ]).pipe(
    tap(
      ([buyers, slaughterPeriods]) => {
        this.dataSource = this.supplierService.getSupplierContractDataSource(
          this.useIfNoPersistedConfig(this.getInitialConfig(slaughterPeriods))
        );
        this.persistSmartTableConfig();
        this.setSettings(slaughterPeriods, buyers);
      },
      () => {
        this.toastrService.showError("Contract data wasn't upload");
      }
    )
  );

  constructor(
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private supplierService: SupplierService,
    private slaughterPeriodService: SlaughterPeriodService,
    private buyerService: BuyerService,
    private toastrService: ToastrService,
    protected smartTableConfigPersistenceService: SmartTableConfigPersistenceService
  ) {
    super(router, smartTableConfigPersistenceService);
  }

  onEdit($event: any) {
    const slaughterPeriodFilter = this.dataSource
      .getFilter()
      .filters.find((data) => data.field === 'slaughterPeriodId');

    this.router.navigate(['./edit/' + $event.data.id], {
      queryParams: { slaughterPeriodId: slaughterPeriodFilter.search },
      relativeTo: this.activatedRoute,
    });
  }

  private getInitialConfig(slaughterPeriods: any): SmartTableConfig {
    const filterConfig = [];
    filterConfig.push(
      new SmartTableFilterConfig('slaughterPeriodId', slaughterPeriods[0].value)
    );

    return new SmartTableConfig(filterConfig);
  }

  private getContractDate(): string {
    return moment().utc().subtract(7, 'd').toDate().toISOString();
  }

  private setSettings(stockClassesRes: any, buyerListRes: any) {
    this.tableSettings = {
      mode: 'external',
      actions: {
        add: false,
        edit: true,
        delete: false,
      },
      edit: {
        editButtonContent: '<i class="eva eva-edit-2-outline"></i>',
      },
      columns: {
        buyerId: {
          title: 'Buyer',
          filter: {
            type: 'custom',
            component: CustomSelectFilterComponent,
            config: {
              selectText: 'Select Buyer',
              list: this.sortData(buyerListRes),
            },
          },
          valuePrepareFunction: this.prepareBuyerColumn,
        },
        slaughterPeriodId: {
          title: 'Week Begin',
          filter: {
            type: 'custom',
            component: CustomSelectFilterComponent,
            config: {
              list: stockClassesRes,
            },
          },
          valuePrepareFunction: this.prepareSlaughterPeriodColumn,
        },
        supplierName: {
          title: 'Supplier Name',
          type: ColumnType.Text,
          filter: {
            type: 'custom',
            component: CustomInputFilterComponent,
          },
        },
        supplyProgramName: {
          title: 'Program Name',
          type: ColumnType.Text,
          filter: {
            type: 'custom',
            component: CustomInputFilterComponent,
          },
        },
        status: {
          title: 'Status',
          type: ColumnType.Text,
          filter: false,
        },
        contractConfirmStatus: {
          title: 'Confirm Status',
          type: ColumnType.Text,
          filter: false,
        },
      },
    };
  }

  private sortData(item: any) {
    if (item !== undefined) {
      return item.sort((a, b) => a.title?.localeCompare(b.title));
    }
  }

  private prepareBuyerColumn(cell, row) {
    return row.buyerName;
  }

  private prepareSlaughterPeriodColumn(cell, row) {
    return row.weekBegin;
  }
}
