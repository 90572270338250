import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../@auth/auth.service';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    const frontChannelLogoutEnabled = window['__env'].logoutUrl !== undefined;
    this.authService.logout(!frontChannelLogoutEnabled);
    if (frontChannelLogoutEnabled) {
      window.location = window['__env'].logoutUrl;
    }
  }
}
