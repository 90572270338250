export interface ThreeAddresses {
  address1: string;
  address2: string;
  address3: string;
}

const RD: string = 'RD';

export function fullAddress(
  addresses: ThreeAddresses,
  removeRd: boolean = false
) {
  let result = '';
  if (addresses.address1?.trim) {
    const lines = addresses.address1.split(/\n/);
    if (removeRd && lines[1]?.includes(RD)) {
      if (lines[0]?.trim) {
        result += lines[0] ?? '';
      }
      if (lines[2]?.trim) {
        result += ' ';
        result += lines[2] ?? '';
      }
    } else {
      result += addresses.address1.replace(/\n/g, ' ');
    }
    if (result.trim) {
      result += ' ';
    }
  }
  if (addresses.address2?.trim) {
    result += addresses.address2.replace(/\n/g, ' ');
    result += ' ';
  }
  if (addresses.address3?.trim) {
    result += addresses.address3.replace(/\n/g, ' ');
  }
  return result;
}
