import { NgModule } from '@angular/core';
import { InputViewComponent } from '@shared/modules/angular2-smart-table/components/input-view/input-view.component';
import { CustomInputFilterComponent } from '@shared/modules/angular2-smart-table/components/input-filter/custom-input-filter.component';
import { CustomSelectFilterComponent } from '@shared/modules/angular2-smart-table/components/custom-select-filter/custom-select-filter.component';
import { DateFilterComponent } from '@shared/modules/angular2-smart-table/components/date-filter/date-filter.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbDatepickerModule,
  NbInputModule,
  NbSelectModule,
} from '@nebular/theme';
import { Angular2SmartTableModule } from 'angular2-smart-table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbInputModule,
    NbDatepickerModule,
    NbSelectModule,
    Angular2SmartTableModule,
  ],
  exports: [
    InputViewComponent,
    CustomInputFilterComponent,
    CustomSelectFilterComponent,
    DateFilterComponent,
    Angular2SmartTableModule,
  ],
  declarations: [
    InputViewComponent,
    CustomInputFilterComponent,
    CustomSelectFilterComponent,
    DateFilterComponent,
  ],
  providers: [],
})
export class ELivestockSmartTableModule {}
