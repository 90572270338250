import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { ArchwizardModule } from 'angular-archwizard';
import {
  DateTimeAdapter,
  MomentDateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { AuthModule } from './@auth/auth.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContractConfirmationModule } from './pages/buyer-management/contracts/contract-confirmation/contract-confirmation.module';
import { SharedModule } from './shared/shared.module';
import { ApplicationInsightsService } from './shared/services/application-insights.service';
import { environment } from '../environments/environment';
import { RaygunErrorHandler } from './app.raygun.setup';
import { ELivestockSmartTableModule } from '@shared/modules/angular2-smart-table/e-livestock-smart-table.module';
import { WafErrorInterceptor } from './interceptors/waf-error.interceptor';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};

const TIME_PICKER_CUSTOM_FORMATS = {
  fullPickerInput: 'DD/MM/YYYY h:mm',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    AuthModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    ThemeModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ContractConfirmationModule,
    SharedModule,
    ELivestockSmartTableModule,
    NgWizardModule.forRoot(ngWizardConfig),
    ArchwizardModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
    { provide: OWL_DATE_TIME_FORMATS, useValue: TIME_PICKER_CUSTOM_FORMATS },
    {
      provide: ApplicationInsightsService,
      useClass: ApplicationInsightsService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: WafErrorInterceptor, multi: true },
    [...getErrorHandlerProviders()],
  ],
})
export class AppModule {}

function getErrorHandlerProviders() {
  if (environment.production) {
    return [{ provide: ErrorHandler, useClass: RaygunErrorHandler }];
  }
  return [];
}
