import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@auth/auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { SwitchComponent } from './pages/switch/switch.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'pop-out',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pop-out/pop-out.module').then((m) => m.PopOutModule),
  },
  { path: 'logout', component: LogoutComponent },
  { path: 'switch', component: SwitchComponent },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
