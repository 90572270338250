import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Returns an array of a given object's own enumerable property names,
 *  iterated in the same order that a normal loop would.
 */
@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  transform(value: Object): string[] {
    return typeof value === 'object' ? Object.keys(value) : [];
  }
}
