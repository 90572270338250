import { Directive, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmartTableConfigPersistenceService } from '@shared/modules/angular2-smart-table/services/smart-table-config-persistence.service';
import {
  SmartTableConfig,
  SmartTableFilterConfig,
} from '@shared/services/types/common';
import { CustomServerDataSource } from '@shared/modules/angular2-smart-table/directives/custom-server-data-source';

@Directive()
export abstract class SmartTableConfigPersistenceDirective
  implements OnDestroy
{
  constructor(
    protected router: Router,
    protected smartTableConfigPersistenceService: SmartTableConfigPersistenceService
  ) {}

  protected readonly dataSource: CustomServerDataSource;

  protected isAlive$: Subject<any> = new Subject();

  protected gridUrl = this.router.url.split('?')[0];

  persistSmartTableConfig() {
    this.dataSource
      .onChanged()
      .pipe(takeUntil(this.isAlive$))
      .subscribe((change) => {
        this.smartTableConfigPersistenceService.set(
          this.gridUrl,
          new SmartTableConfig(
            change.filter.filters,
            change.sort,
            change.paging
          )
        );
      });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(null);
    this.isAlive$.complete();
  }

  protected persistedSmartTableConfig(): SmartTableConfig {
    return this.smartTableConfigPersistenceService?.get(this.gridUrl);
  }

  protected overridePersistedConfigIfPredefined(
    predefinedFilters: SmartTableFilterConfig[]
  ): SmartTableConfig {
    if (predefinedFilters.length) {
      return new SmartTableConfig(predefinedFilters);
    }
    return this.persistedSmartTableConfig();
  }

  protected useIfNoPersistedConfig(config: SmartTableConfig): SmartTableConfig {
    return this.persistedSmartTableConfig().isEmpty()
      ? config
      : this.persistedSmartTableConfig();
  }
}
