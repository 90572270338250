import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  private readonly _apiUrl = `${window['__env'].apiUrl}/users`;

  registerUpdateCurrentUser(): Observable<any> {
    return this.httpClient.get(`${this._apiUrl}/current`);
  }
}
