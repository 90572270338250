import {
  OnInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DefaultFilter } from 'angular2-smart-table';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'ngx-select-filter',
  template: `
    <nb-select
      [ngClass]="inputClass"
      class="form-control"
      [formControl]="inputControl"
      fullWidth
      [placeholder]="_placeholder"
    >
      <nb-option [value]="" *ngIf="column.getFilterConfig().selectText">
        {{ column.getFilterConfig().selectText }}
      </nb-option>
      <nb-option *ngFor="let option of dataSet" [value]="option.value">
        {{ option.title }}
      </nb-option>
    </nb-select>
  `,
  styleUrls: ['./custom-select-filter.component.scss'],
})
export class CustomSelectFilterComponent
  extends DefaultFilter
  implements OnInit, OnChanges
{
  inputControl = new FormControl();

  @Input() itemList: any[];
  @Input() listField: any[];

  dataSet: any;
  _placeholder: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.inputControl.valueChanges
      .pipe(
        map((value) =>
          typeof value === `boolean` ? value?.toString() : value
        ),
        distinctUntilChanged(),
        debounceTime(this.delay)
      )
      .subscribe((value: string) => {
        this.query = value;
        this.setFilter();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.column) {
      const currentColumn = changes.column.currentValue.id;
      this.dataSet =
        changes.column.currentValue.dataSet.columnSettings[
          currentColumn
        ].filter.config.list;
      this._placeholder =
        changes.column.currentValue.dataSet.columnSettings[currentColumn].filter
          .config.selectText || 'Select Value';
    }

    if (changes.query) {
      this.inputControl.setValue(changes.query.currentValue);
    }
  }
}
