import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultIfEmpty',
})
export class DefaultIfEmptyPipe implements PipeTransform {
  transform(
    value: string | number,
    defaultValue: string | number
  ): string | number {
    return value || defaultValue;
  }
}
